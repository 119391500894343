





























import { Component, Prop, Vue } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import {
  CHART_CLICK_EVENT,
  TASQ_PROLONGED_ANOMALY_TYPE,
  TASQ_STATE_CHANGE_TYPE
} from '@/lib/constants';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
  },
})
export default class TasqSignal extends Vue {
  @Prop({ required: true }) signalData!: {
    id: number,
    name: string,
    unit?: string,
    dataset: string[]
    time: string[]
    annotations?: [],
  }

  chartLoaded = false;

  signalVisible = true;

  highlightChart = false;

  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,

        label: ((tooltipItems) => {
            const label =tooltipItems.value|| '';
            if(label){
              return `${parseFloat(label).toFixed(1)}`
            }else {
              return `${label}`
            }
        })
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    onClick: (evt) => {
      const chart =  (this.$refs.chart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const date = this.chartData.datasets[0].data[item[0]._index].x;
		if (date != null) {
			this.$eventBus.$emit(CHART_CLICK_EVENT, date.toISOString());
		}
        
      }
    },
    annotation: {
      annotations: [] as any,
    },
  }

  get activeTasq(): any {
    return tasqsListModule.activeTasq;
  }

  created() {
    this.chartData.datasets[0].data = this.signalData.dataset;
    this.chartData.labels = this.signalData.time;
    if (this.activeTasq?.predictionType === TASQ_STATE_CHANGE_TYPE
      || this.activeTasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE
    ) {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq?.stateChangeStart ? `${this.activeTasq?.stateChangeStart}Z` : '',
            xMax: this.activeTasq?.stateChangeEnd ? `${this.activeTasq?.stateChangeEnd}Z` : '',
          }],
        },
      });
    } else if (this.activeTasq?.predictionType === TASQ_PROLONGED_ANOMALY_TYPE) {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq?.stateChangeStart ? `${this.activeTasq?.stateChangeStart}Z` : '',
            xMax: '',
          }],
        },
      });
    } else if (this.activeTasq?.overriddenName === 'Real-Time') {
      Object.assign(this.chartOptions, {
        annotation: {
          annotations: [{
            type: 'box',
            xScaleID: 'x-axis-0',
            borderColor: 'rgba(255, 95, 51, .6)',
            backgroundColor: (this.chartData.labels.length && this.activeTasq.outcome) && this.activeTasq?.outcome?.replace(/\s/g, '') === 'liquidloading' ? 'rgba(255, 204, 51, .6)' : 'rgba(255, 95, 51, .6)',
            xMin: this.activeTasq.rtStartDate || '',
            xMax: '',
          }],
        },
      });
    }
    this.chartLoaded = true;
  }
}
